import Joi from "joi-browser";
import { Offcanvas } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toast } from "react-toastify";

//  Images
import closeIcon from "include/images/dark-close-icon.svg";
import userIcon from "include/images/user.png";

//  Components
import AlertError from "common/alert/alertError";
import AlertSuccess from "common/alert/alertSuccess";
import Form from "common/form/form";
import NextButton from "common/form/submitButton";
import { freePoints, freeSpins, updateUserCreditsReceived } from "store/telegram";

class AddFlyer extends Form {
  state = {
    loading: false,
    data: {
      rewards: "",
    },
    errors: {},
    loadingCredits: false,
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.showModal !== prevProps.showModal) {
      this.clearData();
    }
  };
  doSubmit = (e) => {
    const errors = structuredClone(this.state.errors);
    const data = structuredClone(this.state.data);
    if (isNaN(data.rewards) || data.rewards == 0) {
      errors.rewards = "Rewards cannot be 0";
      return this.setState({ errors });
    }

    this.setState({ loadingCredits: true });
    const payLoad = {};
    if (this.props.rewardType === 1) {
      payLoad.points = parseInt(data.rewards);
      this.props.freePoints(this.props.user?._id, payLoad, this.callback);
    } else {
      payLoad.spinBalance = parseInt(data.rewards);
      this.props.freeSpins(this.props.user?._id, payLoad, this.callback);
    }
  };
  callback = (res) => {
    this.setState({
      loadingCredits: false,
    });
    if (res.status === 200) {
      this.props.updateUserCreditsReceived({
        id: this.props.user?._id,
        rewardType: this.props.rewardType,
        data: parseInt(this.state.data.rewards),
      });
      toast(<AlertSuccess message="Information Saved" />, {
        containerId: 1,
      });
      this.props.toggleModal();
      this.clearData();
    } else {
      toast(<AlertError message={res.data && res.data.message} />, {
        containerId: 1,
      });
    }
  };
  schema = {
    rewards: Joi.alternatives()
      .try(Joi.string(), Joi.number())
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = "Reward is required.";
              break;
          }
        });
        return errors;
      }),
  };
  clearData = () => {
    this.setState({
      loading: false,
      data: { rewards: "" },
      errors: {},
      loadingCredits: false,
    });
  };

  render() {
    const { user, rewardType } = this.props;
    return (
      <Offcanvas
        className={"offcanvas offcanvas-end custom-offcanvas-pannel"}
        show={this.props.showModal}
        onHide={this.props.toggleModal}
        id="rewardPannel"
        placement="right"
        name="rewardPannel"
        backdrop={true}
        backdropClassName={"modal-backdrop z-index-1000"}
      >
        <Offcanvas.Header className="offCanvas-header-2">
          <div>
            <h5>Free {rewardType === 1 ? "Points" : "Spins"}</h5>
            <div className="td-img-group d-flex align-items-start m-top-2">
              <div className="td-img round-img m-right-2 pointer">
                <img src={user?.image?.small ? process.env.REACT_APP_CDN + user.image.small : userIcon} alt="" />
              </div>
              {user?.firstName} {user?.lastName}
              <br />
              {user?.userName}
            </div>
          </div>
          <div
            className="btn_close pointer"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={this.props.toggleModal}
          >
            <img src={closeIcon} alt="" />
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="form-check-outer">
            <div className="new-form-group">
              <table className="table large-table flyer-table">
                {/* <thead>
                  <tr>
                    <th>{rewardType === 1 ? "Tickets" : rewardType === 2 ? "Arcade Coins" : "Loot Bucks"}</th>
                  </tr>
                </thead> */}
                <tbody>
                  <tr className="flyer-table">
                    <td>
                      <input
                        name="credit"
                        type={"number"}
                        value={this.state.data.rewards}
                        className={`form-control ${this.state.errors.rewards && this.state.errors.rewards && "error"}`}
                        onChange={(e) => {
                          this.setState({ data: { rewards: e.target.value } });
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Offcanvas.Body>
        <div className="offcanvas-footer">
          <NextButton
            classData="btn btn-default btn-block h-100"
            label="Save"
            loading={this.state.loadingCredits}
            handleSubmit={this.handleSubmit}
          />
        </div>
      </Offcanvas>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  freePoints: (id, data, callback) => dispatch(freePoints(id, data, callback)),
  freeSpins: (id, data, callback) => dispatch(freeSpins(id, data, callback)),
  updateUserCreditsReceived: (payLoad) => dispatch(updateUserCreditsReceived(payLoad)),
});

export default withRouter(connect(null, mapDispatchToProps)(AddFlyer));
